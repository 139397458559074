





































































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { WorkingPeriodResource } from '@/classes/WorkingPeriod.resource';
import { copyInviteText, getMeetOptions, getPossibleEndTime } from '../helper/index';
import { SelectItemOption } from '@/classes/base/elements';
import FlipCountdown from 'vue2-flip-countdown';

@Component({
	components: {
		BaseSelect: () => import('../components/base/BaseSelect.vue'),
		BaseButton: () => import('../components/base/BaseButton.vue'),
		BaseDialog: () => import('../components/base/BaseDialog.vue'),
		CountdownFlip: () => import('../components/CountdownFlip.vue'),
		BaseToolTip: () => import('../components/base/BaseToolTip.vue'),
		FlipCountdown
	}
})
export default class NextMeetCard extends Vue {
	@Prop({ default: null })
	nextDueWorkingPeriod: WorkingPeriodResource;

	meetToDelete: WorkingPeriodResource = null as unknown as WorkingPeriodResource;
	inviteMeet: WorkingPeriodResource = null as unknown as WorkingPeriodResource;

	public confirmMeetDelete = false;
	public inviteDialog = false;

	async openMeet(id: number): Promise<void> {
		this.$router.push(`/meeting/${id}`);
	}

	async editMeet(id: number): Promise<void> {
		this.$router.push(`/create/${id}/edit`);
	}

	async invite(): Promise<void> {
		this.inviteDialog = true;
	}
	async getInviteText(): Promise<void> {
		copyInviteText(this.nextDueWorkingPeriod);
	}

	async removeMeet(): Promise<void> {
		this.$emit('delete-meet', this.meetToDelete);
	}

	getNextDueWorkingPeriodOptions(workingPeriod: WorkingPeriodResource): SelectItemOption[] {
		return getMeetOptions(workingPeriod);
	}

	getNextDueWorkingPeriodEndTime(workingPeriod: WorkingPeriodResource): string {
		return getPossibleEndTime(workingPeriod);
	}

	get locationIsUrl(): boolean {
		//const regex = "/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/";
		var expression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
		var regex = new RegExp(expression);
		if (this.nextDueWorkingPeriod.place.match(regex)) {
			return true;
		} else {
			return false;
		}
	}
}
